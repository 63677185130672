<template lang="pug">
  .monthpicker
    AppDropdown.months(
      :disabled="isDisabled"
      close-on-select
      :value="selectedMonth"
      valueKey="title"
      titleKey="title"
      :items="monthRange"
      @select="selectMonth"
    )
    .action-buttons
      AppButton.arrow(
        :disabled="isDisabled || isPrevMonthDisabled"
        skip-translation
        title="<",
        @click="selectPrevMonth"
      )
      AppButton.today(
        :disabled="isDisabled"
        name="today"
        title="inventory_management.current_month"
        @click="selectCurrentMonth"
      )
      AppButton.arrow(
        :disabled="isDisabled || isNextMonthDisabled"
        skip-translation
        title=">",
        @click="selectNextMonth"
      )
</template>

<script>
  // misc
  import { map, findIndex } from "lodash-es"
  import { getMonthRange } from "@/helpers/dates"
  import { DATE_FNS_MONTH_FORMAT } from "@/config/constants"
  import { startOfMonth, endOfMonth, compareAsc, format as dateFormat } from "date-fns"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    watch: {
      enabled: {
        handler() {
          if (this.enabled) {
            this.setInitialMonth()
            this.handleMonthSelection()
          }
        }
      }
    },

    data() {
      return {
        selectedMonthIndex: 0
      }
    },

    mounted() {
      this.setInitialMonth()
      if (this.enabled) {
        this.handleMonthSelection()
      }
    },

    props: {
      initialDate: {
        type: Date,
        required: true
      },
      startDate: {
        type: Date,
        required: true
      },
      endDate: {
        type: Date,
        required: true
      },
      enabled: {
        type: Boolean,
        default: true
      },
      useStartOfMonth: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      startOfMonth,
      endOfMonth,

      setInitialMonth() {
        this.selectedMonthIndex = findIndex(this.monthRange, ({ title }) => title === this.initialMonthName)
      },

      selectMonth(month) {
        this.selectedMonthIndex = this.monthRange.indexOf(month)
        this.handleMonthSelection()
      },

      selectCurrentMonth() {
        this.selectedMonthIndex = 1
        this.handleMonthSelection()
      },

      selectNextMonth() {
        this.selectedMonthIndex += 1
        this.handleMonthSelection()
      },

      selectPrevMonth() {
        this.selectedMonthIndex -= 1
        this.handleMonthSelection()
      },

      getSelectedMonthMinDate() {
        const startOfMonth = this.startOfMonth(this.selectedMonthStartDate)
        if (this.useStartOfMonth) {
          return startOfMonth
        }
        return compareAsc(this.startDate, startOfMonth) >= 0 ? this.startDate : startOfMonth
      },

      getSelectedMonthMaxDate() {
        const endOfMonth = this.endOfMonth(this.selectedMonthStartDate)
        return compareAsc(endOfMonth, this.endDate) >= 0 ? this.endDate : endOfMonth
      },

      getSelectedMonthDateRange() {
        return [this.getSelectedMonthMinDate(), this.getSelectedMonthMaxDate()]
      },

      handleMonthSelection() {
        this.$emit("change", this.getSelectedMonthDateRange())
      }
    },

    computed: {
      isDisabled({ enabled }) {
        return !enabled
      },

      monthRange({ startDate, endDate }) {
        return map(getMonthRange(startDate, endDate), date => ({
          title: dateFormat(date, DATE_FNS_MONTH_FORMAT),
          date: date
        }))
      },

      initialMonthName({ initialDate }) {
        return dateFormat(startOfMonth(initialDate), DATE_FNS_MONTH_FORMAT)
      },

      selectedMonth({ monthRange, selectedMonthIndex }) {
        return monthRange[selectedMonthIndex]
      },

      selectedMonthStartDate() {
        return this.selectedMonth?.date
      },

      isPrevMonthDisabled() {
        return this.selectedMonthIndex === 0
      },

      isNextMonthDisabled() {
        return this.selectedMonthIndex === this.monthRange.length - 1
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .monthpicker
    display: flex
    justify-content: space-between

    .action-buttons
      display: flex

      .months
        margin-right: 5px

      .today
        padding: 5px 10px
        margin-left: 10px
        margin-right: 10px
        width: 75px

      button
        color: $default-purple
        border: 1px solid $default-purple
        height: 38px
        margin-top: 5px

        &.arrow
          padding: 0 15px
</style>
